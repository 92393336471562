/**
*		- Displays an "update browser" message in IE 10 and below
*/
document.addEventListener("DOMContentLoaded", function () {
	if (isIE10 || isIE11) {
		var rpf = document.querySelector("#rpf .container");
		var browserUpdate = document.createElement('div');
		browserUpdate.setAttribute("id", "browser-update");
		browserUpdate.innerHTML = '<p>' +
			'<strong>Your browser is out of date!</strong><br>' +
			'This site takes advantage of new web technologies that only modern browsers have access to. Please consider upgrading your browser to <em>improve your web experience here and on sites like Gmail, YouTube, and Facebook</em>. Outdated browsers are also a <em>major source of security flaws</em>.<br>' +
			'<strong>We suggest using <a href="https://www.google.com/chrome" target="_blank">Google Chrome</a>, <a href="https://www.mozilla.org/firefox/" target="_blank">Firefox</a>, or <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank">Microsoft Edge</a>.</strong>' +
			'</p>';
		rpf.insertBefore(browserUpdate, rpf.children[0]);
		rpf.removeChild(rpf.querySelector("aside"));
		rpf.removeChild(rpf.querySelector("main"));
	}
});